import * as React from "react"
import {useState} from "react";
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
  PropsDoc, ThemesDoc} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

     
export default function NextJsGuideComponent () {

  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  let [isOpen, setIsOpen] = useState(false);
  let [startingIndex, setStartingIndex] = useState(0);

  let [isOpen1, setIsOpen1] = useState(false)

  const images = [
    {
      src: 'https://source.unsplash.com/sQZ_A17cufs/549x711',
      alt: 'Mechanical keyboard with white keycaps.',
    },
    {
      src: 'https://source.unsplash.com/rsAeSMzOX9Y/768x512',
      alt: 'Mechanical keyboard with white, pastel green and red keycaps.',
    },
    {
      src: 'https://source.unsplash.com/Z6SXt1v5tP8/768x512',
      alt: 'Mechanical keyboard with white, pastel pink, yellow and red keycaps.',
    },
  ]

  return <Layout>
  <Header />
  <section className="section section--gradient font-inter bg_white">
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-gray-900 mb-4 font-bold">Open/close the lightbox</h1>

                      <p className="mb-8">
                        In this guide, we'll be taking a look at how to open or close the lightbox by toggling the <code>open</code>
                        {" "} prop.
                      </p>

                    
                    <h3>Demo</h3>
                    <p>Firstly, let's take a look at a demo of this in action. 
                    </p>
                    <p>
                    Just click on the button below to open the lightbox:
                    </p>
                    <SlideshowLightbox 
                        open={isOpen} 
                        startingSlideIndex={startingIndex}
                        images={images}
                        showThumbnails={true}
                        onClose={() => setIsOpen(false)} 
                        lightboxIdentifier="lbox1">
                    </SlideshowLightbox>
                    <button className="bg-blue-500 rounded-lg text-white px-4 py-4 mb-4 hover:bg-blue-600" onClick={() => {setIsOpen(true)}}> 
                        Open Lightbox
                    </button>

                    <p className="pt-4">Rather than requiring the user to click an image to open the lightbox, which 
                    is the default behaviour, it is opened 
                        via the button instead. This can be useful if you want to implement custom functionality 
                        for opening/closing the lightbox.
                    </p>
                      
                    <h3 id="import" className="h3 text-gray-900 font-bold text-3xl mt-4" style={{ scrollMarginTop: '100px' }}>Import</h3>

                    <p>
                    To get started, import the <code>SlideshowLightbox</code> component required, as well as the CSS file which provides 
                    the styling for the library:
                    </p>

                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-8 codeBlock">
                        {`import 'lightbox.js-react/dist/index.css'\nimport {SlideshowLightbox} from 'lightbox.js-react'`}
                        </SyntaxHighlighter>
                    </div>

                    <h3 className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>
                        Adding the open prop
                    </h3>
                    <p>
                    If you'd like to open or close the lightbox, this can be done by toggling the <code>open</code> {" "}
                        prop of the lightbox.
                    </p>

                    <p>
                        Also, if you'd like to specify the starting index that the lightbox should open to, simply pass an index value 
                        to the <code>startingSlideIndex</code> {" "} prop. This is optional however, and the default value is <code>0</code>.
                    </p>
                   <p>Here is the JSX required:</p>
                   <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`<SlideshowLightbox 
  open={isOpen} 
  startingSlideIndex={startingIndex}
  images={images}
  onClose={() => setIsOpen(false)} 
  lightboxIdentifier="lbox1">
 ...
</SlideshowLightbox>
`}
                        </SyntaxHighlighter>

                        <p className="pt-4">To keep track of the open and starting index state, two state variables are required, created 
                            with the useState Hook:
                        </p>

                        <SyntaxHighlighter language="js" style={oneDark} className="mb-8 codeBlock">
                        {` let [isOpen, setIsOpen] = useState(false);
 let [startingIndex, setStartingIndex] = useState(0);
`}              
                        </SyntaxHighlighter>

                
                    </div>

                    <p>Next up, an array of images needs to be created, which is then passed to the SlideshowLightbox component.</p>                    
                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`const images = [
  {
    src: 'https://source.unsplash.com/sQZ_A17cufs/549x711',
    alt: 'Mechanical keyboard with white keycaps.',
  },
  {
    src: 'https://source.unsplash.com/rsAeSMzOX9Y/768x512',
    alt: 'Mechanical keyboard with white, pastel green and red keycaps.',
  },
  {
    src: 'https://source.unsplash.com/Z6SXt1v5tP8/768x512',
    alt: 'Mechanical keyboard with white, pastel pink, yellow and red keycaps.',
  },
]`}
                        </SyntaxHighlighter>
                    </div>
                    
                    <h3 className="mt-12 text-gray-900 mb-4 text-xl font-semibold" style={{ scrollMarginTop: '100px' }}>
                        Adding An Open Button</h3>
                    <p>This is optional, but if you'd like to try out the open/close functionality, 
                        you could create a button which sets the lightbox's <code>open</code> state to <code>true</code>.
                        When the lightbox is closed, the onClose event will set the open state variable to <code>false</code> {" "}
                        automatically. </p>

                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`<button onClick={() => {setIsOpen(true)}}> 
Open Lightbox
</button>
`}
                        </SyntaxHighlighter>

                    <h1 className="mt-8 pt-4">Full Example</h1>

                    <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
      {`import React from 'react'
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
          
function Demo() {

    let [isOpen, setIsOpen] = useState(false);
    let [startingIndex, setStartingIndex] = useState(0); //optional
  
    const images = [
      {
        src: 'https://source.unsplash.com/sQZ_A17cufs/549x711',
        alt: 'Mechanical keyboard with white keycaps.',
      },
      {
        src: 'https://source.unsplash.com/rsAeSMzOX9Y/768x512',
        alt: 'Mechanical keyboard with white, pastel green and red keycaps.',
      },
      {
        src: 'https://source.unsplash.com/Z6SXt1v5tP8/768x512',
        alt: 'Mechanical keyboard with white, pastel pink, yellow and red keycaps.',
      },
    ]

return (
    <div>
        <SlideshowLightbox 
            open={isOpen} 
            startingSlideIndex={startingIndex}
            images={images}
            showThumbnails={true}
            onClose={() => setIsOpen(false)} 
            lightboxIdentifier="lbox1">
        </SlideshowLightbox>
        <button className="bg-indigo-500 rounded-lg text-white px-4 py-4 transition hover:bg-indigo-600" 
        onClick={() => {setIsOpen(true)}}> 
            Open Lightbox
        </button>
    </div>
      )
}`}
        </SyntaxHighlighter>


                    <p className="mt-4">Here's a demo of the above, just click the button below to open the lightbox:</p>
                    <SlideshowLightbox 
                        open={isOpen1} 
                        startingSlideIndex={startingIndex}
                        images={images}
                        showThumbnails={true}
                        onClose={() => setIsOpen1(false)} 
                        lightboxIdentifier="lbox1">
                    </SlideshowLightbox>
                    <button className="bg-indigo-500 rounded-lg text-white px-4 py-4 mb-4 transition hover:bg-indigo-600" 
                    onClick={() => {setIsOpen(true)}}> 
                        Open Lightbox
                    </button>


                    <article className="mb-8 mt-8">
                      <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>
                    <p>If you'd like to customize the lightbox, be sure to take a look at the <Link className="text-underline text-blue-600" 
                    to="/docs/SlideshowLightbox">
                        SlideshowLightbox</Link> {" "}
                     documentation, which lists the various customization options and props you can update to edit the 
                    theme and so forth.</p>
                   
      </article>
      
    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <Link className="flex justify-between items-center p-4 rounded border border-gray-200 
      transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/SlideshowLightbox">
        <div>
          <div className="text-normal font-medium mb-1">Full SlideshowLightbox docs</div>
          <div className="text-sm text-gray-600">See how to customize the SlideshowLightbox in the complete documentation here.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </Link>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
      duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
        <div>
          <div className="text-normal font-medium mb-1">Feature request</div>
          <div className="text-sm text-gray-600">Want to request a new feature? Just open an issue in our GitHub repository!</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>
          
  </section>
    <Footer overrideDarkTheme={true} />
  </Layout>
  
}